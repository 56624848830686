export default defineNuxtRouteMiddleware((to, _from) => {
  const ichi_token = useCookie('ichi_token')
  const localPath = useLocalePath()
  const redirect = to.fullPath
  const isLogin = !!ichi_token.value

  if (!isLogin) {
    return navigateTo(localPath({ path: '/login', query: { redirect } }))
  }
})
